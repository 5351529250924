import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import AddIcon from "../assets/icons/addIcon";
import ContactIcon from "../assets/icons/contactIcon";
// import OverviewIcon from "../assets/icons/overviewIcon";
import SettingIcon from "../assets/icons/settingIcon";
import LogoutIcon from "../assets/icons/logoutIcon";

export default function SideBar() {
  const params = useParams();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/");
  };
  return (
    <div className="sidebar d-flex flex-column justify-content-between">
      <div className="sidebar-content">
        <h1>Paper Notes</h1>
        <ul className="list-unstyled">
          <li>
            <NavLink
              to="/campaigns"
              className={
                params?.id
                  ? "active text-decoration-none"
                  : "text-decoration-none"
              }
            >
              <AddIcon /> Campaigns
            </NavLink>
          </li>
          <li>
            <NavLink to="/customers" className="text-decoration-none">
              <ContactIcon /> Customers
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/" className="text-decoration-none">
              <OverviewIcon /> Overview
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/settings" className="text-decoration-none">
              <SettingIcon /> Settings
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="logout-btn">
        <Button
          color="link"
          className="d-flex align-items-center"
          onClick={() => handleLogout()}
        >
          <LogoutIcon /> Log Out
        </Button>
      </div>
    </div>
  );
}
