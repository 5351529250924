import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Input } from "reactstrap";
import PlusCircle from "../assets/icons/plus-circle";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, postRequest } from "../utils/ApiFunctions";
import { formatDate } from "../utils/helper";
import Spinner from "../components/Spinner";
import { campaignEvent, campaignFilter } from "../utils/contant";

export default function CampaignDetail() {
  const [response, setResponse] = useState({});
  const [imagesURL, setImagesURL] = useState([]);
  const [postAmount, setPostAmount] = useState("");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = () => {
    navigate(`/campaign/customers/${response?.id}`, {
      state: {
        idx: response?.id,
      },
    });
  };

  useEffect(() => {
    if (!!params?.id) {
      getCampaigns(params?.id);
    }
  }, [params]);

  const getCampaigns = async (idx) => {
    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
    };
    const res = await getRequest(`/get_campaign?campaign_id=${idx}`, headers);
    if (res.status === 200) {
      setResponse(res?.campaign);
      let arr = [];
      arr.push(res.campaign.back_image);
      arr.push(res.campaign.front_image);
      setImagesURL(arr);
    }
  };

  const fileDownloadHandler = async (text) => {
    try {
      const downloadPromises = imagesURL.map(async (imageUrl, index) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${text}_img-${index + 1}.png`;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      });

      await Promise.all(downloadPromises);
    } catch (error) {
      console.error("Error downloading images: ", error);
    }
  };

  const convertToCSV = (jsonData) => {
    const headers = Object.keys(jsonData[0]);
    const csvContent = headers.join(",") + "\n";
    const rows = jsonData.map((row) =>
      headers
        .map((fieldName) => {
          if (fieldName === "address") {
            let address = JSON.parse(row[fieldName]);
            address = JSON.parse(address);
            return JSON.stringify(
              `${address.address1}, ${address.province}, ${address.city}, ${address.country}, ${address.zip}`
            );
          }
          if (fieldName === "campaign") {
            let campaignDet = `Name: ${row[fieldName].name}, Event: ${row[fieldName].event}`;
            return JSON.stringify(campaignDet);
          }
          return JSON.stringify(row[fieldName]);
        })
        .join(",")
    );
    return csvContent + rows.join("\n");
  };

  const handleChange = (e) => {
    setPostAmount(e.target.value);
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(response.customers);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${response.name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePostAmount = async (idx) => {
    setBtnLoading(true);
    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
      "Content-Type": "application/json",
    };
    const data = {
      id: idx,
      cost_per_card: postAmount,
    };

    const res = await postRequest(
      "/update_postcard_cost",
      JSON.stringify(data),
      headers
    );

    if (res.status === 200) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
      if (!!params?.id) {
        getCampaigns(params?.id);
      }
    }

    setBtnLoading(false);
  };

  useEffect(() => {
    if (Object.keys(response).length > 0) {
      setPostAmount(response.cost_per_card);
    }
  }, [response]);

  const filterType = campaignEvent.find((itm) => itm.value == response?.event);

  return (
    <>
      {Object.keys(response).length > 0 && (
        <div className="campaign-details-container">
          {isSuccess && (
            <Alert color="success" className="position-absolute">
              Successfully updated!!!
            </Alert>
          )}
          <div className="dashboard-table campaign-details mt-3">
            <h2>Campaign Detail</h2>
            <p>Campaign Information</p>
            <div className="campaign-postcard-amount d-flex">
              <div className="position-relative custom-input me-3">
                <label>Amount per postcard</label>
                <Input
                  type="number"
                  name="postAmount"
                  id="postAmount"
                  value={postAmount}
                  onChange={(e) => handleChange(e)}
                  placeholder="Amounts per postcard"
                  autoComplete="off"
                  min={0}
                />
              </div>
              <Button
                disabled={
                  postAmount < 1 || postAmount == response.cost_per_card
                }
                onClick={() => handlePostAmount(response.id)}
              >
                {isBtnLoading ? "Loading..." : "Save"}
              </Button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Store name</th>
                  <th>Campaign Event</th>
                  <th>Budget</th>
                  <th>Spent</th>
                  <th>$Per Postcard</th>
                  <th>Start Date</th>
                  <th>Stop Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{response.name}</td>
                  <td>{response.shop}</td>
                  <td>{filterType?.label}</td>
                  <td>${response.budget}</td>
                  <td>${response.spent}</td>
                  <td>${response.cost_per_card}</td>
                  <td>{formatDate(response.start_date)}</td>
                  <td>{formatDate(response.end_date)}</td>
                  <td>
                    <Badge
                      color={response.status == "ended" ? "danger" : "primary"}
                      pill
                      className={
                        response.status === "pending"
                          ? "in-progress-badge"
                          : response.status === "on_going"
                          ? "active-badge"
                          : "paused-badge"
                      }
                    >
                      {response.status === "pending"
                        ? "Pending"
                        : response.status === "on_going"
                        ? "On Going"
                        : response.status == "ended"
                        ? "Ended"
                        : "Paused"}
                    </Badge>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-end">
              <Button
                onClick={downloadCSV}
                disabled={response.customers.length < 1}
              >
                Download Campaign Details
              </Button>
            </div>
            <div className="campaign-details-customer">
              <div className="d-flex align-items-center">
                <h3>Customers</h3>
                {response.customers.length > 0 && (
                  <div className="ms-4">
                    {response.customers.slice(0, 10).map((item, index) => {
                      return (
                        <Badge className="paused-badge mx-1" key={index}>
                          {item.name}
                        </Badge>
                      );
                    })}
                  </div>
                )}
                {response.customers.length < 1 && (
                  <span className="ms-3 fw-bold">No customers available</span>
                )}
              </div>
            </div>
            <div className="text-end mt-3">
              <Button
                color="link"
                className="text-decoration-none p-0"
                onClick={handleNavigate}
              >
                See all customers details
              </Button>
            </div>
          </div>
          <div className="campaign-details dashboard-table mt-3 mb-4">
            <h2>Postcard Detail</h2>
            <div className="card-container d-flex align-items-center my-5">
              <div className="card me-3">
                <PlusCircle />
                <h4>Customer Pending</h4>
                <span>{response.pending_count}</span>
              </div>
              <div className="card sent me-3">
                <PlusCircle />
                <h4>Sent</h4>
                <span>{response.sent_count}</span>
              </div>
              <div className="card production">
                <PlusCircle />
                <h4>Production</h4>
                <span>{response.production_count}</span>
              </div>
            </div>
            <div className="campaign-design-cont">
              <h5>Design</h5>
              <div className="campaign-design-content mt-4 d-flex align-items-center justify-content-between">
                <div className="campaign-design d-flex">
                  <div className="d-flex flex-column align-items-start">
                    <img src={response.front_image} alt="" className="me-3" />
                    <Badge className="paused-badge mt-2">Front Image</Badge>
                  </div>
                  <div className="d-flex flex-column align-items-start">
                    <img src={response.back_image} alt="" />
                    <Badge className="paused-badge mt-2">Back Image</Badge>
                  </div>
                </div>
                <div className="campaign-design-text">
                  <p>You can download The design from here</p>
                  <Button
                    onClick={() => fileDownloadHandler(response.name)}
                    color="link"
                    className="p-0"
                  >
                    Download Design
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="text-end mt-4">
          <Button>Download Postcards Details</Button>
        </div> */}
          </div>
        </div>
      )}
      {Object.keys(response).length < 1 && (
        <div className="d-flex justify-content-center m-4 mt-5 pt-5">
          <Spinner />
        </div>
      )}
    </>
  );
}
