import React from "react";

export default function ContactIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 0H15.5C16.3284 0 17 0.677165 17 1.51249V19.7213C17 19.896 16.7946 19.9881 16.666 19.871L15.0017 18.3562C14.8114 18.183 14.522 18.183 14.3316 18.3562L12.6684 19.8701C12.478 20.0433 12.1886 20.0433 11.9983 19.8701L10.335 18.3562C10.1447 18.183 9.85529 18.183 9.66498 18.3562L8.00169 19.8701C7.81137 20.0433 7.52196 20.0433 7.33164 19.8701L5.66836 18.3562C5.47804 18.183 5.18863 18.183 4.99831 18.3562L3.33401 19.871C3.20537 19.9881 3 19.896 3 19.7213V1.51249C3 0.677165 3.67157 0 4.5 0ZM10.0008 7.59603H8.41379L9.70679 6.30303C9.88894 6.11443 9.98974 5.86183 9.98746 5.59963C9.98518 5.33744 9.88001 5.08662 9.6946 4.90122C9.5092 4.71581 9.25838 4.61064 8.99619 4.60836C8.73399 4.60608 8.48139 4.70688 8.29279 4.88903L5.29279 7.88903C5.10532 8.07656 5 8.33087 5 8.59603C5 8.8612 5.10532 9.11551 5.29279 9.30303L8.29279 12.303C8.38503 12.3985 8.49538 12.4747 8.61738 12.5271C8.73939 12.5795 8.87061 12.6071 9.00339 12.6083C9.13616 12.6094 9.26784 12.5841 9.39074 12.5339C9.51364 12.4836 9.62529 12.4093 9.71918 12.3154C9.81307 12.2215 9.88733 12.1099 9.93761 11.987C9.98789 11.8641 10.0132 11.7324 10.012 11.5996C10.0109 11.4669 9.9833 11.3356 9.93089 11.2136C9.87848 11.0916 9.8023 10.9813 9.70679 10.889L8.41379 9.59603H10.0008C12.0698 9.59603 12.9998 10.712 12.9998 12.596C12.9998 12.8613 13.1051 13.1156 13.2927 13.3031C13.4802 13.4907 13.7346 13.596 13.9998 13.596C14.265 13.596 14.5194 13.4907 14.7069 13.3031C14.8944 13.1156 14.9998 12.8613 14.9998 12.596C14.9998 9.68003 13.2638 7.59603 10.0008 7.59603Z"
        fill="white"
      />
    </svg>
  );
}
