import React from "react";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export default function CustomerModal({ isOpen, toggle, customerDetails }) {
  const convertToCSV = (jsonData) => {
    const headers = Object.keys(jsonData);
    const csvContent = headers.join(",") + "\n";
    const rows = headers
      .map((fieldName) => {
        if (fieldName === "address") {
          let address = JSON.parse(jsonData[fieldName]);
          address = JSON.parse(address);
          return JSON.stringify(
            `${address.address1}, ${address.province}, ${address.city}, ${address.country}, ${address.zip}`
          );
        }
        if (fieldName === "campaign") {
          let campaignDet = `Name: ${jsonData[fieldName].name}, Event: ${jsonData[fieldName].event}`;
          return JSON.stringify(campaignDet);
        }
        return JSON.stringify(jsonData[fieldName]);
      })
      .join(",");
    return csvContent + rows;
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(customerDetails[0]);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${customerDetails[0].name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let address = {};
  if (Object.keys(customerDetails[0])?.length > 0) {
    address = JSON.parse(customerDetails[0].address);
    address = JSON.parse(address);
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Customer Detail</ModalHeader>
        {Object.keys(customerDetails[0])?.length > 0 && (
          <ModalBody>
            <div className="customer-modal-details">
              <div className="d-flex align-items-center customer-modal-content">
                <p>Name</p>
                <p>{customerDetails[0].name}</p>
              </div>
              <div className="d-flex align-items-center customer-modal-content">
                <p>Store Name</p>
                <p>{customerDetails[0].campaign.shop}</p>
              </div>
              <div className="d-flex align-items-center customer-modal-content">
                <p>Address</p>
                <p>{`${address.address1 || "-"}, ${address.province || "-"}, ${
                  address.city || "-"
                }, ${address.country || "-"}, ${address.zip || "-"}`}</p>
              </div>
              {/* <div className="d-flex align-items-center customer-modal-content">
                <p>Status</p>
                <p>{customerDetails.status}</p>
              </div> */}
              <div className="">
                <h5>Campaigns</h5>
                {customerDetails.map((item, index) => {
                  return (
                    <Badge className="paused-badge me-2" key={index}>
                      {item?.campaign?.name}
                    </Badge>
                  );
                })}
              </div>
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <Button color="primary light-btn" onClick={toggle}>
            Cancel
          </Button>
          <Button color="secondary" onClick={() => downloadCSV()}>
            Download Customer Details
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
