import React from "react";

export default function ArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
    >
      <path
        d="M0.611328 0.688477L5.23431 5.3114L9.85723 0.688477"
        stroke="#030303"
        strokeLinecap="round"
      />
    </svg>
  );
}
