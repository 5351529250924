import React, { useEffect, useRef, useState } from "react";
import { Button, Input } from "reactstrap";
import SearchIcon from "../assets/icons/searchIcon";
import ArrowIcon from "../assets/icons/arrowIcon";

export default function PageHeader({
  handleSearch,
  searchValue,
  title,
  searchText,
  filters,
  handleFilterValue,
  filterValue,
}) {
  const [isVisible, setVisible] = useState(false);
  const filterRef = useRef();

  const handleFilterShow = () => {
    setVisible(!isVisible);
  };

  const handleClickOutside = (e) => {
    if (filterRef.current && !filterRef.current.contains(e.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const filteredData = filters.find(item => item.value === filterValue);

  return (
    <div className="page-header d-flex align-items-center justify-content-between">
      <div className="page-header-title">
        <h2>{title}</h2>
      </div>
      <div className="d-flex align-items-center">
        <div className="page-header-filter position-relative" ref={filterRef}>
          <Button
            onClick={handleFilterShow}
            className="d-flex align-items-center"
          >
            {filteredData.label} <ArrowIcon />
          </Button>
          {isVisible && (
            <div className="page-header-filter-list">
              <ul className="list-unstyled p-0 mb-0">
                {filters.length > 0 &&
                  filters.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          setVisible(false);
                          handleFilterValue(item.value);
                        }}
                        className={item.value === filterValue ? "active" : ""}
                      >
                        {item.label}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
        <div className="page-header-search ms-3 position-relative">
          <Input
            type="text"
            placeholder={searchText}
            onChange={handleSearch}
            value={searchValue}
          />
          <div className="position-absolute search-icon-div">
            <SearchIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
