import React from "react";

export default function LeftArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 16.0002C11.7442 16.0002 11.4883 15.9022 11.2933 15.7072L6.29325 10.7073C5.90225 10.3163 5.90225 9.68425 6.29325 9.29325L11.2933 4.29325C11.6842 3.90225 12.3163 3.90225 12.7073 4.29325C13.0982 4.68425 13.0982 5.31625 12.7073 5.70725L8.41425 10.0002L12.7073 14.2933C13.0982 14.6842 13.0982 15.3162 12.7073 15.7072C12.5122 15.9022 12.2562 16.0002 12.0002 16.0002Z"
        fill="#5C5F62"
      />
    </svg>
  );
}
