import React from "react";

export default function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M6.03273 12.3355C6.20831 12.16 6.29655 11.9296 6.29655 11.6992C6.29655 11.4688 6.20831 11.2384 6.03273 11.0629L4.86854 9.89921L10.7985 9.89921C11.2964 9.89921 11.6989 9.49601 11.6989 8.99921C11.6989 8.50241 11.2964 8.09921 10.7985 8.09921L4.86854 8.09921L6.03273 6.93551C6.38478 6.58361 6.38478 6.01481 6.03273 5.66291C5.68068 5.31101 5.11164 5.31101 4.75959 5.66291L2.05843 8.36291C1.9801 8.44121 1.92428 8.53391 1.88016 8.63111C1.86908 8.65563 1.86148 8.68014 1.85369 8.70527C1.85262 8.70874 1.85154 8.71222 1.85044 8.71571C1.82433 8.79131 1.80993 8.86781 1.80542 8.94701C1.8049 8.95517 1.80422 8.96317 1.80354 8.97113C1.8019 8.9904 1.80029 9.00943 1.80092 9.02981C1.80362 9.12701 1.81983 9.22421 1.85675 9.31781C1.8581 9.32141 1.85877 9.32524 1.85945 9.32906C1.86012 9.33289 1.8608 9.33671 1.86215 9.34031L1.86305 9.34391C1.90537 9.44471 1.96749 9.53111 2.03953 9.60851C2.04358 9.61256 2.04628 9.61729 2.04898 9.62201C2.05168 9.62674 2.05438 9.63146 2.05843 9.63551L4.75959 12.3355C5.11164 12.6874 5.68068 12.6874 6.03273 12.3355Z"
        fill="#02005F"
      />
      <path
        d="M8.10038 14.8492C8.10038 15.5948 8.7048 16.1992 9.45038 16.1992L14.8504 16.1992C15.596 16.1992 16.2004 15.5948 16.2004 14.8492L16.2004 3.14922C16.2004 2.40363 15.596 1.79922 14.8504 1.79922L9.45038 1.79922C8.7048 1.79922 8.10038 2.40363 8.10038 3.14922L8.10038 6.29922L9.90038 6.29922L9.90038 3.59922L14.4004 3.59922L14.4004 14.3992L9.90038 14.3992L9.90038 11.6992L8.10038 11.6992L8.10038 14.8492Z"
        fill="#02005F"
      />
    </svg>
  );
}
