import { apiUrl } from "./ApiConstant";

export async function getRequest(url, headers) {
  const url_api = `${apiUrl}${url}`;
  const response = await fetch(url_api, {
    method: "GET",
    headers: headers
  });
  const res = await response.json();
  return res;
}

export async function postRequest(url, data, headers = {}) {
  const url_api = `${apiUrl}${url}`;
  const response = await fetch(url_api, {
    method: "POST",
    body: data,
    headers: headers,
  });
  const res = await response.json();
  return res;
}
