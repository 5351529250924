import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/Header";
import { Col, Container, Row } from "reactstrap";
import SideBar from "../components/SideBar";

export default function PrivateRoutes() {
  return !!localStorage.getItem("token") ? (
    <Container fluid>
      <Row>
        <Col xl={2} md={3}>
          <SideBar />
        </Col>
        <Col xl={10} md={9}>
          <Header />
          <div className="main-content mb-5">
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  ) : (
    <Navigate to="/" />
  );
}
