import React from "react";

export default function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9998 1.7998C5.02991 1.7998 1.7998 5.02991 1.7998 8.9998C1.7998 12.9697 5.02991 16.1998 8.9998 16.1998C12.9697 16.1998 16.1998 12.9697 16.1998 8.9998C16.1998 5.02991 12.9697 1.7998 8.9998 1.7998ZM8.9998 14.3998C6.0217 14.3998 3.5998 11.9779 3.5998 8.9998C3.5998 6.0217 6.0217 3.5998 8.9998 3.5998C11.9779 3.5998 14.3998 6.0217 14.3998 8.9998C14.3998 11.9779 11.9779 14.3998 8.9998 14.3998ZM11.6998 8.0998H9.89981V6.2998C9.89981 5.803 9.4975 5.3998 8.9998 5.3998C8.5021 5.3998 8.0998 5.803 8.0998 6.2998V8.0998H6.2998C5.8021 8.0998 5.3998 8.50301 5.3998 8.9998C5.3998 9.4966 5.8021 9.89981 6.2998 9.89981H8.0998V11.6998C8.0998 12.1966 8.5021 12.5998 8.9998 12.5998C9.4975 12.5998 9.89981 12.1966 9.89981 11.6998V9.89981H11.6998C12.1975 9.89981 12.5998 9.4966 12.5998 8.9998C12.5998 8.50301 12.1975 8.0998 11.6998 8.0998Z"
        fill="#219FFF"
      />
    </svg>
  );
}
