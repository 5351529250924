export function getProfileFromName(name) {
    const firstName = name?.split(" ")[0];
    const lastName = name?.split(" ")[1] || null;
    const initials = `${firstName?.charAt(0)}${
        !!lastName ? lastName?.charAt(0) : ""
    }`;
    return initials;
}

export function formatDate(timestamp) {
    const date = new Date(timestamp);

    // Format the date as "Sept 21, 2023"
    const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short', // Short month name (e.g., "Sept")
    day: 'numeric', // Numeric day of the month (e.g., "21")
    year: 'numeric' // Numeric year (e.g., "2023")
    }).format(date);
    return formattedDate;
}