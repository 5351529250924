import React from "react";

export default function PlusCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
    >
      <rect
        x="0.529297"
        y="0.046875"
        width="38"
        height="38"
        rx="19"
        fill="#F0F9FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5284 9.30078C14.1552 9.30078 9.7832 13.6727 9.7832 19.046C9.7832 24.4193 14.1552 28.7912 19.5284 28.7912C24.9017 28.7912 29.2737 24.4193 29.2737 19.046C29.2737 13.6727 24.9017 9.30078 19.5284 9.30078ZM19.5284 26.3549C15.4976 26.3549 12.2195 23.0769 12.2195 19.046C12.2195 15.0151 15.4976 11.7371 19.5284 11.7371C23.5593 11.7371 26.8374 15.0151 26.8374 19.046C26.8374 23.0769 23.5593 26.3549 19.5284 26.3549ZM23.1829 17.8279H20.7466V15.3916C20.7466 14.7191 20.2021 14.1734 19.5284 14.1734C18.8548 14.1734 18.3103 14.7191 18.3103 15.3916V17.8279H15.874C15.2003 17.8279 14.6558 18.3736 14.6558 19.046C14.6558 19.7184 15.2003 20.2642 15.874 20.2642H18.3103V22.7005C18.3103 23.3729 18.8548 23.9186 19.5284 23.9186C20.2021 23.9186 20.7466 23.3729 20.7466 22.7005V20.2642H23.1829C23.8565 20.2642 24.401 19.7184 24.401 19.046C24.401 18.3736 23.8565 17.8279 23.1829 17.8279Z"
        fill="#54B5FD"
      />
    </svg>
  );
}
