import React from "react";

export default function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <rect width="38" height="38" rx="19" fill="#F0F9FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0008 21.6998C17.5095 21.6998 16.3008 20.4911 16.3008 18.9998C16.3008 17.5085 17.5095 16.2998 19.0008 16.2998C20.4921 16.2998 21.7008 17.5085 21.7008 18.9998C21.7008 20.4911 20.4921 21.6998 19.0008 21.6998ZM25.7292 20.5784C24.4746 19.8998 24.4746 18.0998 25.7292 17.4212C25.947 17.3033 26.1063 17.1008 26.1702 16.8623C26.2341 16.6229 26.1972 16.3682 26.0676 16.1576L25.1073 14.5979C24.8571 14.192 24.3333 14.0516 23.913 14.2784L23.5107 14.4971C22.3146 15.1442 20.8602 14.2775 20.8602 12.9176V12.6998C20.8602 12.203 20.4579 11.7998 19.9602 11.7998H18.0414C17.5437 11.7998 17.1414 12.203 17.1414 12.6998V12.9176C17.1414 14.2775 15.687 15.1442 14.4909 14.4971L14.0886 14.2784C13.6683 14.0516 13.1445 14.192 12.8943 14.5979L11.934 16.1576C11.8044 16.3682 11.7675 16.6229 11.8314 16.8623C11.8953 17.1008 12.0546 17.3033 12.2724 17.4212C13.527 18.0998 13.527 19.8998 12.2724 20.5784C12.0546 20.6963 11.8953 20.8988 11.8314 21.1373C11.7675 21.3767 11.8044 21.6314 11.934 21.842L12.8943 23.4017C13.1445 23.8076 13.6683 23.948 14.0886 23.7212L14.4909 23.5025C15.687 22.8554 17.1414 23.7221 17.1414 25.082V25.2998C17.1414 25.7966 17.5437 26.1998 18.0414 26.1998H19.9602C20.4579 26.1998 20.8602 25.7966 20.8602 25.2998V25.082C20.8602 23.7221 22.3146 22.8554 23.5107 23.5025L23.913 23.7212C24.3333 23.948 24.8571 23.8076 25.1073 23.4017L26.0676 21.842C26.1972 21.6314 26.2341 21.3767 26.1702 21.1373C26.1063 20.8988 25.947 20.6963 25.7292 20.5784Z"
        fill="#54B5FD"
      />
    </svg>
  );
}
