import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import EmailImg from "../assets/images/email.svg";
import PassImg from "../assets/images/pass.svg";
import { loginFields } from "../utils/contant";
import { apiUrl } from "../utils/ApiConstant";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [fields, setFeilds] = useState(loginFields);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeilds((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: fields.adminEmail,
      password: fields.adminPassword,
    };
    fetch(`${apiUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response?.is_logged) {
          localStorage.setItem("token", response?.user?.api_token);
          localStorage.setItem("userData", JSON.stringify(response?.user));
          navigate("/campaigns");
        } else {
          setErrorMessage(response?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <Container className="py-5">
      <Row>
        <Col>
          <div className="text-center pb-5 main-heading">
            <h1 className="m-0">
              Paper <span>Notes</span>
            </h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Card className="mt-5 custom-card">
            <div className="text-center mb-4 position-relative">
              <h2>Welcome Back</h2>
              <p>Welcome Back Please Login To Your Account</p>
            {!!errorMessage && <span className="input-error login-error">{errorMessage}</span>}
            </div>
            <Form>
              <FormGroup>
                <div className="position-relative custom-input">
                  <Input
                    type="email"
                    name="adminEmail"
                    id="adminEmail"
                    value={fields.adminEmail}
                    onChange={(e) => handleChange(e)}
                    placeholder="Email"
                    autoComplete="off"
                  />
                  <div className="prefix-icon">
                    <img src={EmailImg} alt="" />
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="position-relative custom-input">
                  <Input
                    type="password"
                    name="adminPassword"
                    id="adminPassword"
                    value={fields.adminPassword}
                    onChange={(e) => handleChange(e)}
                    placeholder="Password"
                  />
                  <div className="prefix-icon">
                    <img src={PassImg} alt="" />
                  </div>
                </div>
              </FormGroup>
              {/* <div className="d-flex align-items-center justify-content-end mb-5">
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Remember me
                  </Label>
                </FormGroup>
                <Button color="link">Forgot Password?</Button>
              </div> */}
              <Button
                size="lg"
                block
                className="logged-in mt-4"
                onClick={handleLogin}
                disabled={
                  !fields.adminEmail || !fields.adminPassword || isLoading
                }
              >
                {isLoading ? "Loading..." : "Log In"}
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
