import React, { useEffect, useState } from "react";
import { getProfileFromName } from "../utils/helper";

export default function Header() {
  const [adName, setAdName] = useState("");

  useEffect(() => {
    const data = localStorage.getItem("userData");
    if (!!data) {
      const obj = JSON.parse(data);
      setAdName(obj.name);
    }
  }, []);

  return (
    <div className="header">
      {adName !== "" ? (
        <div className="profile-image">
          <div className="initials">{getProfileFromName(adName)}</div>
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
}
