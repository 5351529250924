import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../components/PageHeader";
import { Badge, Button } from "reactstrap";
import LeftArrow from "../assets/icons/leftArrow";
import RightArrow from "../assets/icons/rightArrow";
import { Link, useNavigate } from "react-router-dom";
import { getRequest } from "../utils/ApiFunctions";
import { campaignEvent, campaignFilter } from "../utils/contant";
import Spinner from "../components/Spinner";
import { formatDate } from "../utils/helper";

const PageSize = 10;

export default function Dashboard() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [response, setResponse] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getCampaigns();
  }, []);

  const getCampaigns = async () => {
    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
    };
    const res = await getRequest(`/get_campaigns`, headers);
    if (res.status === 200) {
      setResponse(res.campaigns);
    }
    setLoading(false);
    if (res.status === 401) {
      localStorage.removeItem("token");
      navigate("/");
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return response
      ?.filter((item) =>
        searchValue === ""
          ? item.status.toLowerCase().includes(filterValue.toLowerCase())
          : (item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              item.shop.toLowerCase().includes(searchValue.toLowerCase())) &&
            item.status.toLowerCase().includes(filterValue.toLowerCase())
      )
      .slice(firstPageIndex, lastPageIndex);
  }, [currentPage, response, searchValue, filterValue]);

  useEffect(() => {
    const totalPagesRounded = Math.ceil(response?.length / PageSize);
    if (totalPagesRounded === 0) {
      setTotalPages(1);
    } else {
      setTotalPages(Math.ceil(response?.length / PageSize));
    }
  }, [response]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == "") {
      setTotalPages(Math.ceil(response.length / PageSize));
    } else {
      let arr = response.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      if (arr.length > 0) {
        setTotalPages(Math.ceil(arr.length / PageSize));
      } else {
        setTotalPages(1);
      }
    }
    setCurrentPage(1);
  };

  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFilterValue = (val) => {
    setFilterValue(val);
    setCurrentPage(1);
    if (val == "") {
      setTotalPages(Math.ceil(response.length / PageSize));
    } else {
      let arr = response.filter((item) => item.status == val);
      if (arr.length > 0) {
        setTotalPages(Math.ceil(arr.length / PageSize));
      } else {
        setTotalPages(1);
      }
    }
  };

  return (
    <div className="dashboard-content">
      <PageHeader
        handleSearch={handleSearch}
        searchValue={searchValue}
        title="All Campaigns"
        searchText="Search Campaigns"
        filters={campaignFilter}
        handleFilterValue={handleFilterValue}
        filterValue={filterValue}
      />
      <div className="dashboard-table my-5">
        <table>
          <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Store name</th>
              <th>Campaign Event</th>
              <th>Status</th>
              <th>Budget</th>
              <th>Start Date</th>
              <th>Stop Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              currentTableData?.length > 0 &&
              currentTableData.map((item, index) => {
                const filteredData = campaignEvent.find(
                  (itm) => itm.value === item.event
                );
                return (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <img src={item.front_image} alt="" />
                        <h5 className="ms-2">{item.name}</h5>
                      </div>
                    </td>
                    <td>{item.shop}</td>
                    <td>{filteredData?.label}</td>
                    <td>
                      <Badge
                        color={item.status == "ended" ? "danger" : "primary"}
                        pill
                        className={
                          item.status === "pending"
                            ? "in-progress-badge"
                            : item.status === "on_going"
                            ? "active-badge"
                            : "paused-badge"
                        }
                      >
                        {item.status === "pending"
                          ? "Pending"
                          : item.status === "on_going"
                          ? "On Going"
                          : item.status == "ended"
                          ? "Ended"
                          : "Paused"}
                      </Badge>
                    </td>
                    <td>${item.budget}</td>
                    <td>{formatDate(item.start_date)}</td>
                    <td>{formatDate(item.end_date)}</td>
                    <td>
                      <Link
                        to={`/campaign/${item.id}`}
                        className="text-decoration-none p-0"
                      >
                        Details
                      </Link>
                    </td>
                  </tr>
                );
              })}
            {!isLoading && currentTableData?.length < 1 && (
              <tr>
                <td colSpan={7}>
                  <div className="empty-view text-center">
                    <h5>No campaigns yet</h5>
                    <p>Try changing the filters or search term</p>
                  </div>
                </td>
              </tr>
            )}
            {isLoading && (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex justify-content-center m-4">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {response?.length > PageSize && (
          <div className="table-pagination text-end mt-4">
            <Button
              className="bg-white px-2"
              disabled={currentPage === 1}
              onClick={onPrevious}
            >
              <LeftArrow />
            </Button>
            <span className="mx-3">
              {currentPage} of {totalPages} page
            </span>
            <Button
              className="bg-white px-2"
              onClick={onNext}
              disabled={totalPages === currentPage}
            >
              <RightArrow />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
