import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../components/PageHeader";
import LeftArrow from "../assets/icons/leftArrow";
import RightArrow from "../assets/icons/rightArrow";
import { useLocation, useParams } from "react-router-dom";
import CustomerTableRow from "../components/CustomerTableRow";
import { getRequest } from "../utils/ApiFunctions";
import { Alert, Button } from "reactstrap";
import { customerFilter } from "../utils/contant";
import CustomerModal from "../components/CustomerModal";
import Spinner from "../components/Spinner";

const PageSize = 10;

export default function Contact() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [customersList, setCustomersList] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [isError, setError] = useState(false);
  const [error, setErrorMessage] = useState("Something went wrong");
  const [isSuccess, setSuccess] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [isModalLoading, setModalLoading] = useState(false);

  const location = useLocation();
  const params = useParams();

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return customersList
      ?.filter((item) =>
        searchValue === ""
          ? item.status.toLowerCase().includes(filterValue.toLowerCase()) &&
            !!item.campaign
          : item.name.toLowerCase().includes(searchValue.toLowerCase()) &&
            item.status.toLowerCase().includes(filterValue.toLowerCase()) &&
            !!item.campaign
      )
      .slice(firstPageIndex, lastPageIndex);
  }, [currentPage, customersList, searchValue, filterValue]);

  useEffect(() => {
    const totalPagesRounded = Math.ceil(
      customersList?.customers?.length / PageSize
    );
    if (totalPagesRounded === 0) {
      setTotalPages(1);
    } else {
      setTotalPages(Math.ceil(customersList?.customers?.length / PageSize));
    }
  }, [customersList]);

  useEffect(() => {
    setLoading(true);
    handleCustomerList(location);
  }, [location]);

  const handleCustomerList = (location) => {
    if (!!params?.id | (!!params?.id && isSuccess)) {
      getCampaigns(params?.id);
    } else if (isSuccess) {
      getCustomers();
    } else {
      getCustomers();
    }
  };

  const getCampaigns = async (idx) => {
    setLoading(true);
    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
    };
    const res = await getRequest(`/get_campaign?campaign_id=${idx}`, headers);
    setLoading(false);
    if (res.status === 200) {
      setCampaignName(res?.campaign.name);
      setCustomersList(res?.campaign.customers);
    }
  };

  const getCustomers = async () => {
    setLoading(true);
    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
    };
    const res = await getRequest(`/get_customers`, headers);
    setLoading(false);
    if (res.status === 200) {
      setCustomersList(res?.data);
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == "") {
      setTotalPages(Math.ceil(customersList.length / PageSize));
    } else {
      let arr = customersList.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      if (arr.length > 0) {
        setTotalPages(Math.ceil(arr.length / PageSize));
      } else {
        setTotalPages(1);
      }
    }
    setCurrentPage(1);
  };

  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFilterValue = (val) => {
    setFilterValue(val);
    setCurrentPage(1);
    if (val == "") {
      setTotalPages(Math.ceil(customersList.length / PageSize));
    } else {
      let arr = customersList.filter((item) => item.status == val);
      if (arr.length > 0) {
        setTotalPages(Math.ceil(arr.length / PageSize));
      } else {
        setTotalPages(1);
      }
    }
  };

  const handleModal = () => {
    setOpen(!isOpen);
  };

  const handleModalData = async (idx) => {
    setModalLoading(true);
    setOpen(true);

    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
    };

    const res = await getRequest(
      `/get_customer?shopify_customer_id=${idx}`,
      headers
    );
    setModalLoading(false);
    if (Object.keys(res).length > 0 && !!res.id) {
      setCustomerDetails(res.customer_campaigns);
    }
  };

  let nameCamp = (!!campaignName && !!params.id) ? `(` + campaignName + `)` : "";

  return (
    <div className="dashboard-content">
      <PageHeader
        handleSearch={handleSearch}
        searchValue={searchValue}
        title={`Customer Details ${nameCamp}`}
        searchText="Search Customers"
        filters={customerFilter}
        handleFilterValue={handleFilterValue}
        filterValue={filterValue}
      />
      {isError && (
        <Alert
          color="danger"
          className="position-absolute"
          isOpen={isError}
          toggle={() => setError(false)}
        >
          {error}
        </Alert>
      )}
      {isSuccess && (
        <Alert color="success" className="position-absolute">
          Successfully updated!!!
        </Alert>
      )}
      <div className="dashboard-table mt-5">
        <table>
          <thead>
            <tr>
              <th>Customer name</th>
              <th>Campaign Name</th>
              <th>Status</th>
              <th>Created on</th>
              <th>Location</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              currentTableData?.length > 0 &&
              currentTableData?.map((item, index) => {
                let address = JSON.parse(item.address);
                if (typeof address == "string") {
                  address = JSON.parse(address);
                }
                return (
                  !!item.campaign && (
                    <CustomerTableRow
                      item={item}
                      key={index}
                      address={address}
                      handleModalData={handleModalData}
                      setError={setError}
                      setErrorMessage={setErrorMessage}
                      setSuccess={setSuccess}
                      handleCustomerList={handleCustomerList}
                      setModalLoading={setModalLoading}
                    />
                  )
                );
              })}
            {!isLoading && currentTableData?.length < 1 && (
              <tr>
                <td colSpan={7}>
                  <div className="empty-view text-center">
                    <h5>No Customers yet</h5>
                    <p>Try changing the filters or search term</p>
                  </div>
                </td>
              </tr>
            )}
            {isLoading && (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex justify-content-center m-4">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isModalLoading && (
          <div className="global-loader">
            <div className="d-flex justify-content-center m-4">
              <Spinner />
            </div>
          </div>
        )}
        {customersList?.customers?.length > 10 && (
          <div className="table-pagination text-end mt-4">
            <Button
              className="bg-white px-2"
              disabled={currentPage === 1}
              onClick={onPrevious}
            >
              <LeftArrow />
            </Button>
            <span className="mx-3">
              {currentPage} of {totalPages} page
            </span>
            <Button
              className="bg-white px-2"
              onClick={onNext}
              disabled={totalPages === currentPage}
            >
              <RightArrow />
            </Button>
          </div>
        )}
      </div>
      {isOpen && customerDetails.length > 0 && (
        <CustomerModal
          isOpen={isOpen}
          toggle={handleModal}
          customerDetails={customerDetails}
        />
      )}
    </div>
  );
}
