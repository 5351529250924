import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./views/Login";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import Dashboard from "./views/Dashboard";
import Settings from "./views/Settings";
import Contact from "./views/Contact";
import CampaignDetail from "./views/CampaignDetail";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/campaigns" element={<Dashboard />} />
            <Route path="/campaign/:id" element={<CampaignDetail />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/customers" element={<Contact />} />
            <Route path="/campaign/customers/:id" element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
