import React, { useEffect, useState } from "react";
import SettingsIcon from "../assets/icons/settingsIcon";
import { getProfileFromName } from "../utils/helper";
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { passFields, priceFields } from "../utils/contant";
import { getRequest, postRequest } from "../utils/ApiFunctions";
import editImg from "../assets/images/edit.svg";

export default function Settings() {
  const [activeTab, setActiveTab] = useState("account");
  const [userData, setUserData] = useState({});
  const [fields, setFields] = useState(passFields);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [postAmount, setPostAmount] = useState("");
  const [settingResponse, setSettingResponse] = useState({});
  const [isSkeletonLoad, setSkeletonLoad] = useState(false);
  const [defaultPrice, setDefualtPrice] = useState(priceFields);
  const [selectedLabel, setSelectedLabel] = useState("");

  useEffect(() => {
    let getData = localStorage.getItem("userData");
    getData = JSON.parse(getData);
    if (!!getData && Object.keys(getData).length > 0) {
      setUserData(getData);
    }
  }, []);

  const handleTabChange = (val) => {
    setActiveTab(val);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleClose = () => {
    setOpen(!isOpen);
    setPostAmount("");
  };

  const handleOpenModal = (val) => {
    setSelectedLabel(val);
    setOpen(!isOpen);
  };

  const handleInput = (e) => {
    setPostAmount(e.target.value);
    setDefualtPrice({
      ...defaultPrice,
      [selectedLabel]: e.target.value,
    });
  };

  const handleReset = async () => {
    setLoading(true);
    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
      "Content-Type": "application/json",
    };
    if (fields.newPassword.length > 5) {
      if (fields.newPassword === fields.confirmPassword) {
        const data = {
          old_password: fields.oldPassword,
          new_password: fields.newPassword,
        };
        const res = await postRequest(
          "/reset_pass",
          JSON.stringify(data),
          headers
        );

        if (res.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
          localStorage.setItem("token", res.user.api_token);
          setFields(passFields);
          setError("");
        } else {
          if (res.status == 422) {
            setError(res.errors[0]);
          }
          if (res.status == 401) {
            setError(res.message);
          }
        }
        setLoading(false);
      } else {
        setError("Password mismatch");
        setLoading(false);
      }
    } else {
      setError("Password must be atleast 6 characters long");
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSettings();
  }, []);

  const handleGetSettings = async () => {
    setSkeletonLoad(true);
    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
      "Content-Type": "application/json",
    };

    const res = await getRequest("/get_general_settings", headers);
    setSkeletonLoad(false);
    if (res.status == 200) {
      setSettingResponse(res?.data);
      setDefualtPrice({
        price_4X6: res?.data?.price_4X6,
        price_6X9: res?.data?.price_6X9,
        price_6X11: res?.data?.price_6X11,
      });
    }
  };

  const handlePostBudget = async () => {
    setLoading(true);
    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
      "Content-Type": "application/json",
    };

    const data = {
      price_4X6: Number(defaultPrice.price_4X6),
      price_6X9: Number(defaultPrice.price_6X9),
      price_6X11: Number(defaultPrice.price_6X11),
    };

    const res = await postRequest(
      "/update_general_settings",
      JSON.stringify(data),
      headers
    );

    if (res.status == 200) {
      setSettingResponse({
        ...settingResponse, ...defaultPrice
      })
      setSuccess(true);
      handleGetSettings();
      setOpen(false);
      setPostAmount("");
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }

    setLoading(false);
  };

  return (
    <div className="settngs-container">
      <div className="page-header-title mb-4 mt-5">
        <h2>Settings</h2>
      </div>
      {isSuccess && (
        <Alert color="success" className="position-absolute">
          Successfully updated!!!
        </Alert>
      )}
      <div className="settings-tab d-flex align-items-center">
        <div
          className={
            activeTab === "account"
              ? "d-flex align-items-center settings-tab-content me-4 active"
              : "d-flex align-items-center settings-tab-content me-4"
          }
          onClick={() => handleTabChange("account")}
        >
          <SettingsIcon />
          <h6 className="ms-3">Account</h6>
        </div>
        <div
          className={
            activeTab === "password"
              ? "d-flex align-items-center settings-tab-content active"
              : "d-flex align-items-center settings-tab-content"
          }
          onClick={() => handleTabChange("password")}
        >
          <SettingsIcon />
          <h6 className="ms-3">Reset Password</h6>
        </div>
      </div>
      {activeTab === "account" && Object.keys(userData).length > 0 && (
        <div className="settings-content-main mt-4">
          <div className="settings-profile-content">
            <p>Profile</p>
            <div className="d-flex w-100">
              <div className="profile-image me-3">
                <div className="initials">
                  {getProfileFromName(userData.name)}
                </div>
              </div>
              <div className="w-100">
                <div className="d-flex align-items-center customer-setting-content">
                  <p className="m-0">Name</p>
                  <p className="m-0">{userData.name}</p>
                </div>
                <div className="d-flex align-items-center customer-setting-content">
                  <p className="m-0">Email</p>
                  <p className="m-0">{userData.email}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="settings-profile-content mt-4">
            <p>Postcard Setting</p>
            <div className="d-flex align-items-center">
              <h6 className="mb-0">Amount Per Postcard</h6>
              {/* <Button
                className="bg-transparent border-0 p-0 ms-3"
                onClick={() => handleClose()}
              >
                <img src={editImg} alt="edit" />
              </Button> */}
            </div>
            {/* <h4 className="mt-2">
              {!isSkeletonLoad ? (
                `$${settingResponse?.default_price_per_card || 0}`
              ) : (
                <div className="loader-container">
                  <div className="cell"></div>
                </div>
              )}
            </h4> */}

            {/* New design for postcard price based on different sizes */}

            <div className="w-100">
              <div className="d-flex align-items-center customer-setting-content">
                <p className="m-0 fw-bold">Size</p>
                <p className="m-0 fw-bold">Price</p>
              </div>
              <div className="d-flex align-items-center customer-setting-content">
                <p className="m-0">4X6</p>
                <div className="d-flex align-items-center">
                  {!isSkeletonLoad ? (
                    <p className="m-0">{`$${settingResponse?.price_4X6 || 0}`}</p>
                  ) : (
                    <div className="loader-container">
                      <div className="cell"></div>
                    </div>
                  )}
                  <Button
                    className="bg-transparent border-0 p-0 ms-3"
                    onClick={() => handleOpenModal("price_4X6")}
                  >
                    <img src={editImg} alt="edit" />
                  </Button>
                </div>
              </div>
              <div className="d-flex align-items-center customer-setting-content">
                <p className="m-0">6X9</p>
                <div className="d-flex align-items-center">
                  {!isSkeletonLoad ? (
                    <p className="m-0">{`$${settingResponse?.price_6X9 || 0}`}</p>
                  ) : (
                    <div className="loader-container">
                      <div className="cell"></div>
                    </div>
                  )}
                  <Button
                    className="bg-transparent border-0 p-0 ms-3"
                    onClick={() => handleOpenModal("price_6X9")}
                  >
                    <img src={editImg} alt="edit" />
                  </Button>
                </div>
              </div>
              <div className="d-flex align-items-center customer-setting-content">
                <p className="m-0">6X11</p>
                <div className="d-flex align-items-center">
                  {!isSkeletonLoad ? (
                    <p className="m-0">{`$${settingResponse?.price_6X11 || 0}`}</p>
                  ) : (
                    <div className="loader-container">
                      <div className="cell"></div>
                    </div>
                  )}
                  <Button
                    className="bg-transparent border-0 p-0 ms-3"
                    onClick={() => handleOpenModal("price_6X11")}
                  >
                    <img src={editImg} alt="edit" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={isOpen}
            toggle={handleClose}
            className="settings-modal"
          >
            <ModalHeader toggle={handleClose}>
              Set Fixed Postcard Price
            </ModalHeader>
            <ModalBody>
              <div className="campaign-postcard-amount d-flex">
                <div className="position-relative custom-input mx-3 mt-2 mb-2 w-100">
                  <Input
                    type="number"
                    name="postAmount"
                    id="postAmount"
                    value={postAmount}
                    onChange={(e) => handleInput(e)}
                    placeholder="Amounts per postcard"
                    autoComplete="off"
                    min={0}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="me-3">
              <Button color="primary light-btn" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={() => handlePostBudget()}
                disabled={!postAmount || isLoading}
              >
                {isLoading ? "Loading..." : "Save"}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
      {activeTab === "password" && (
        <div className="settings-content-main mt-4">
          <div className="settings-password-content">
            <h2>Reset Password</h2>
            {!!error && <span className="input-error">{error}</span>}
            <Form>
              <div className="position-relative custom-input">
                <Input
                  type="password"
                  name="oldPassword"
                  id="oldPassword"
                  value={fields.oldPassword}
                  onChange={(e) => handleChange(e)}
                  placeholder="Old Password"
                  autoComplete="off"
                />
              </div>
              <div className="position-relative custom-input">
                <Input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  value={fields.newPassword}
                  onChange={(e) => handleChange(e)}
                  placeholder="New Password"
                  autoComplete="off"
                />
              </div>
              <div className="position-relative custom-input">
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  value={fields.confirmPassword}
                  onChange={(e) => handleChange(e)}
                  placeholder="Confirm Password"
                  autoComplete="off"
                />
              </div>
              <Button
                size="lg"
                block
                className="logged-in mt-4"
                onClick={handleReset}
                disabled={
                  !fields.newPassword ||
                  !fields.oldPassword ||
                  !fields.confirmPassword ||
                  isLoading
                }
              >
                {isLoading ? "Loading..." : "Change Password"}
              </Button>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
}
