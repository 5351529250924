import React from "react";

export default function SettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99985 11.6998C7.50855 11.6998 6.29985 10.4911 6.29985 8.9998C6.29985 7.5085 7.50855 6.2998 8.99985 6.2998C10.4911 6.2998 11.6998 7.5085 11.6998 8.9998C11.6998 10.4911 10.4911 11.6998 8.99985 11.6998ZM15.7282 10.5784C14.4736 9.89981 14.4736 8.0998 15.7282 7.4212C15.946 7.3033 16.1053 7.1008 16.1692 6.8623C16.2331 6.62291 16.1962 6.36821 16.0666 6.1576L15.1063 4.5979C14.8561 4.192 14.3323 4.0516 13.912 4.2784L13.5097 4.4971C12.3136 5.1442 10.8592 4.2775 10.8592 2.9176V2.6998C10.8592 2.203 10.4569 1.7998 9.95925 1.7998H8.04045C7.54275 1.7998 7.14045 2.203 7.14045 2.6998V2.9176C7.14045 4.2775 5.68605 5.1442 4.48995 4.4971L4.08765 4.2784C3.66735 4.0516 3.14355 4.192 2.89335 4.5979L1.93305 6.1576C1.80345 6.36821 1.76655 6.62291 1.83045 6.8623C1.89435 7.1008 2.05365 7.3033 2.27145 7.4212C3.52605 8.0998 3.52605 9.89981 2.27145 10.5784C2.05365 10.6963 1.89435 10.8988 1.83045 11.1373C1.76655 11.3767 1.80345 11.6314 1.93305 11.842L2.89335 13.4017C3.14355 13.8076 3.66735 13.948 4.08765 13.7212L4.48995 13.5025C5.68605 12.8554 7.14045 13.7221 7.14045 15.082V15.2998C7.14045 15.7966 7.54275 16.1998 8.04045 16.1998H9.95925C10.4569 16.1998 10.8592 15.7966 10.8592 15.2998V15.082C10.8592 13.7221 12.3136 12.8554 13.5097 13.5025L13.912 13.7212C14.3323 13.948 14.8561 13.8076 15.1063 13.4017L16.0666 11.842C16.1962 11.6314 16.2331 11.3767 16.1692 11.1373C16.1053 10.8988 15.946 10.6963 15.7282 10.5784Z"
        fill="white"
      />
    </svg>
  );
}
