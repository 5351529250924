import React from "react";

export default function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M14.1587 13.8472L17.9507 17.644M15.4421 10.7455C15.4421 13.17 13.4766 15.1355 11.0521 15.1355C8.62758 15.1355 6.66211 13.17 6.66211 10.7455C6.66211 8.32094 8.62758 6.35547 11.0521 6.35547C13.4766 6.35547 15.4421 8.32094 15.4421 10.7455Z"
        stroke="#6C6C6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
