import React, { useEffect, useRef, useState } from "react";
import { Badge, Button } from "reactstrap";
import ArrowIcon from "../assets/icons/arrowIcon";
import { formatDate } from "../utils/helper";
import { postRequest } from "../utils/ApiFunctions";

export default function CustomerTableRow({
  item,
  address,
  handleModalData,
  setErrorMessage,
  setError,
  setSuccess,
  handleCustomerList,
  setModalLoading,
}) {
  const [isVisible, setVisible] = useState(false);

  const filterRef = useRef();

  const handleFilterShow = () => {
    setVisible(!isVisible);
  };

  const handleStatusUpdate = async (idx, val) => {
    setModalLoading(true);
    const getToken = localStorage.getItem("token");
    let headers = {
      token: getToken,
      "Content-Type": "application/json",
    };
    const data = {
      id: idx,
      status: val,
    };

    const res = await postRequest(
      "/update_postcard_status",
      JSON.stringify(data),
      headers
    );

    setModalLoading(false);
    if (res?.status === 200) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
      handleCustomerList();
    } else {
      setError(true);
      setErrorMessage(res.message);
    }
  };

  const handleClickOutside = (e) => {
    if (filterRef.current && !filterRef.current.contains(e.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <tr>
        <td>
          <h5 className="ms-2">{item.name}</h5>
        </td>
        <td>{item.campaign && item.campaign?.name}</td>
        <td className="position-relative">
          <div
            onClick={() => handleFilterShow()}
            ref={filterRef}
            className="customer-status-drop"
          >
            <Badge
              color="primary"
              pill
              className={
                item.status === "pending"
                  ? "paused-badge"
                  : item.status === "mailed"
                  ? "active-badge"
                  : "in-progress-badge"
              }
            >
              {item.status}
            </Badge>
            <ArrowIcon />
            {isVisible && (
              <div className="page-header-filter-list">
                <ul className="list-unstyled mb-0">
                  <li onClick={() => handleStatusUpdate(item.id, "pending")}>
                    Pending
                  </li>
                  <li onClick={() => handleStatusUpdate(item.id, "production")}>
                    Production
                  </li>
                  <li onClick={() => handleStatusUpdate(item.id, "mailed")}>
                    Mailed
                  </li>
                </ul>
              </div>
            )}
          </div>
        </td>
        <td>{formatDate(item.created_at)}</td>
        <td>
          <span className="address-col">{`${address.address1 || "-"}, ${
            address.province || "-"
          }, ${address.city || "-"}, ${address.country || "-"}, ${
            address.zip || "-"
          }`}</span>
        </td>
        <td>
          <Button
            color="link"
            className="text-decoration-none p-0"
            onClick={() => handleModalData(item.shopify_customer_id)}
          >
            Details
          </Button>
        </td>
      </tr>
    </>
  );
}
