export const loginFields = {
  adminEmail: "",
  adminPassword: "",
};

export const passFields = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const dummyData = [
  {
    title: "test",
    storeName: "shopify",
    status: "On Going",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
  {
    title: "test1",
    storeName: "shopify",
    status: "Paused",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
  {
    title: "test2",
    storeName: "shopify",
    status: "Paused",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
  {
    title: "test3",
    storeName: "shopify",
    status: "On Going",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
  {
    title: "test4",
    storeName: "shopify",
    status: "On Going",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
  {
    title: "test",
    storeName: "shopify",
    status: "On Going",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
  {
    title: "test1ss",
    storeName: "shopify",
    status: "Paused",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
  {
    title: "test2as",
    storeName: "shopify",
    status: "Paused",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
  {
    title: "test3sas",
    storeName: "shopify",
    status: "On Going",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
  {
    title: "test4sdsd",
    storeName: "shopify",
    status: "On Going",
    Budget: "$200",
    StartDate: "Sept 30, 2023",
    StopDate: "Sept 30, 2023",
  },
];

export const campaignFilter = [
  {
    value: "",
    label: "All",
  },
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "on_going",
    label: "On Going",
  },
  {
    value: "paused",
    label: "Paused",
  },
  {
    value: "ended",
    label: "Ended",
  },
];

export const customerFilter = [
  {
    value: "",
    label: "All",
  },
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "production",
    label: "Production",
  },
  {
    value: "mailed",
    label: "Mailed"
  }
];

export const campaignEvent = [
  {
    value: "after_purchase",
    label: "After Purchase",
  },
  {
    value: "anniversary",
    label: "Anniversary",
  }
];

export const priceFields = {
  price_4X6: 0,
  price_6X9: 0,
  price_6X11: 0
}